import React, { ReactNode, CSSProperties } from "react";
import {
	DataObserveKey,
	DataComponent,
	FocusableComponent,
	VisualComponent,
	Link,
	cn,
} from "@siteimprove/fancylib";
import { TextHighlight } from "../text-highlight/text-highlight";
import * as scss from "./title-url.scss";

export interface TitleUrlSearchQuery {
	query: string | null;
	searchIn?: "title" | "url";
}

export type TitleUrlProps = {
	/** Value for the link */
	url: string;
	/** Value rendered as the link text in the breadcrumb */
	link?: string;
	/** Value for the page title */
	title?: string;
	/** Value for the page title not found / no data */
	titleFallback?: ReactNode;
	/** Value for the query */
	searchQuery?: TitleUrlSearchQuery;
	/** Style CSSProperties values */
	style?: CSSProperties;
	/** Overflow properties*/
	overflow?: "wrap" | "ellipsis";
	/** Should the component display only the title, only the url or both */
	displayMode?: "title" | "url" | "both";
	/** Classname for title display */
	titleClassName?: string;
	/** Classname for url display */
	urlClassName?: string;
	/** data observe key for URL */
	urlDataObserveKey?: string;
} & DataObserveKey &
	DataComponent &
	FocusableComponent &
	VisualComponent;

export function TitleUrl(props: TitleUrlProps): JSX.Element {
	const {
		link,
		title,
		titleFallback,
		url,
		searchQuery,
		style,
		overflow = "wrap",
		displayMode = "both",
		className,
		titleClassName,
		urlClassName,
		urlDataObserveKey,
		onBlur,
		onFocus,
		onKeyDown,
		onMouseDown,
		onMouseEnter,
		onMouseLeave,
		tabIndex,
	} = props;

	const overflowClass = overflow === "ellipsis" && scss.ellipsis;
	const linkText = displayMode !== "url" ? renderTitle() : renderUrl();

	return (
		<div
			data-component={props["data-component"] || "title-url"}
			style={style}
			className={cn(scss.titleUrl, className)}
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			onMouseDown={onMouseDown}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			tabIndex={tabIndex}
		>
			{link !== undefined ? (
				<Link
					className={cn(scss.title, overflowClass, titleClassName)}
					href={link}
					data-observe-key={urlDataObserveKey}
					openNew
				>
					{linkText}
				</Link>
			) : (
				<span className={cn(scss.title, overflowClass, scss.noLink, titleClassName)}>
					{linkText}
				</span>
			)}
			{displayMode === "both" && (
				<span className={cn(scss.url, overflowClass, urlClassName)}>{renderUrl()}</span>
			)}
		</div>
	);

	function renderTitle() {
		if (!title) {
			return titleFallback;
		}

		if (shouldHighlight(searchQuery, "title")) {
			return <TextHighlight needle={searchQuery.query} value={title} />;
		}

		return title;
	}

	function renderUrl() {
		if (shouldHighlight(searchQuery, "url")) {
			return <TextHighlight needle={searchQuery.query} value={url} />;
		}

		return url;
	}
}

function shouldHighlight(
	searchQuery: TitleUrlSearchQuery | undefined,
	focus: "title" | "url"
): searchQuery is TitleUrlSearchQuery {
	if (!searchQuery) {
		return false;
	}
	//If searchIn is undefined it means there is no focus and everything should be highlighted
	return searchQuery.searchIn === undefined || searchQuery.searchIn === focus;
}
