import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { TextContainer, Ul, Tooltip, Paragraph } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { TitleUrl } from "./title-url";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Title Url",
	notepad: "https://hackmd.io/Yk8j7A_nTcyORDM4f0L3lw",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Title Url"
			subTitle="Displays the title and URL of a page. The title acts as a clickable link to the page."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={TitleUrl} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					<Code>TitleUrl</Code> provides a clear and concise way to present the title and URL of a
					page, aiding navigation and enhancing user understanding of link destinations.
				</Paragraph>
				<Example fn={BasicUsage} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={TitleUrl}
					initialProps={{
						title: "Page title",
						titleFallback: (
							<Tooltip
								variant={{ type: "text" }}
								content={"The page title field is either empty or unknown."}
							>
								No page title
							</Tooltip>
						),
						url: "www.siteimprove.com",
						link: "https://www.siteimprove.com",
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>TitleUrl</Code> when
					</Paragraph>
					<Ul
						items={[
							"The page title and URL are both important for user comprehension.",
							"The URL itself offers useful information (e.g., indicating a page title).",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>TitleUrl</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								{" "}
								<GatsbyLink to="/lab/components/Tables%20and%20lists/Table">
									<b>Table</b>
								</GatsbyLink>{" "}
								/{" "}
								<GatsbyLink to="/lab/components/Tables%20and%20lists/List%20table">
									<b>List Table</b>
								</GatsbyLink>{" "}
								: <Code>TitleUrl</Code> is often used to present a page's title (in a larger font)
								and its URL (in a smaller font). This is common in sections like{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FAccessibility%2F446001%2FNextGen%2FIssue%2F1%3Fconformance%3D%26siteTargetIssueKinds%3D1%252C2%26wcagVersion%3D22%26pageSegments%3D%26ruleName%3Dsia-r111%26ruleId%3D111%26issueKind%3D1%26tagFilterType%3D2%26siteTarget.wcagVersion%3D22%26siteTarget.conformanceLevels%3D0%252C1%252C2%26siteTarget.issueKinds%3D1%252C2%26siteTarget.scoreType%3D1%26lang%3Den-US">
									Accessibility {">"} Issue details
								</a>
								.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>TitleUrl</Code> to existing components for visual consistency.
							</>,
							<>
								<b>Hierarchy</b>:
								<Ul
									items={[
										"The title should be the most visually prominent element, usually in a larger font size and with the appropriate link styling (underline).",
										<>
											The URL should be displayed below the title in a smaller font size with{" "}
											<Code>$color--text--static--body</Code> design token.
										</>,
									]}
								/>
							</>,
							<>
								<b>Clarity</b>:
								<Ul
									items={[
										"Use sufficient color contrast between the title, URL, and background to ensure readability.",
										"Follow established typography guidelines for font choices, weights, and line heights.",
										<>
											If the URL is excessively long, truncate it for display using an ellipsis
											("…"). On hover, reveal the full URL in a tooltip or similar mechanism.
										</>,
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"The URL is excessively long, complex, or contains sensitive information.",
							"The title alone provides enough context for the link's destination.",
							<>
								In very constrained spaces where a simpler text link is more appropriate. Use{" "}
								<GatsbyLink to="/lib/components/Navigation/Link">Link</GatsbyLink> instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient color contrast, clear focus states, and intuitive interactions.",
							,
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>
								<b>Title</b>:
								<Ul
									items={[
										<>
											Write concise, descriptive titles that accurately reflect the linked page's
											content.
										</>,
										<>Avoid generic phrases like "Click here." </>,
									]}
								/>
							</>,
							<>
								<b>URL</b>:Ensure the URL is accurate, and up-to-date.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<TitleUrl
		title="Page title"
		titleFallback={
			<Tooltip
				variant={{ type: "text" }}
				content={"The page title field is either empty or unknown."}
			>
				No page title
			</Tooltip>
		}
		url="www.siteimprove.com"
		link="https://www.siteimprove.com" // usually link to the page report
	/>
);
